import React, { useState } from "react";
import { SdirButton, SdirIconButton, SdiTrash, styles } from "@sdir/sds";
import styled from "styled-components";
import { RiskSummary } from "ui/RiskSummary";
import { WorkTask } from "../../types/types";
import { useVesselContext } from "../../context/vesselContext";

export interface TaskProps {
    workTask: WorkTask;
}

const TaskComponent = ({ workTask }: TaskProps) => {
    const vesselContext = useVesselContext();

    return (
        <TaskWrapper>
            <TaskHeader>
                <h2>{workTask.name}</h2>
                <TaskHeaderButtons>
                    <SdirButton
                        onClick={() => {
                            vesselContext.openRiskModalForWorkTask(workTask);
                        }}
                    >
                        Ny fare
                    </SdirButton>
                    <SdirIconButton
                        icon={SdiTrash}
                        onClick={() => {
                            vesselContext.openConfirmDeleteModalForWorkTask?.(workTask);
                        }}
                    ></SdirIconButton>
                </TaskHeaderButtons>
            </TaskHeader>
            {workTask.risks && workTask.risks.length > 0 && (<SmallHeading>Farer</SmallHeading>)}
            {workTask.risks && workTask.risks.length > 0 ? (
                workTask.risks?.map((risk) => (
                    <RiskSummary key={risk.id} risk={risk} workTask={workTask}></RiskSummary>
                ))
            ) : (
                <TaskEmptyState>Ingen farer registrert for denne arbeidsoppgaven</TaskEmptyState>
            )}
        </TaskWrapper>
    );
};

const TaskWrapper = styled.div``;

const TaskHeader = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding-bottom: 12px;
  border-bottom: 1px solid ${styles.colors.primary8};
  margin-bottom: 16px;
  gap: 32px;

  h2 {
    margin: 0;
  }
`;

const TaskHeaderButtons = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  flex-shrink: 0;
`;

const TaskBody = styled.div`
  display: flex;
  flex-direction: column;
`;

const TaskEmptyState = styled.p`
  font-size: ${styles.font.size.md};
  color: ${styles.colors.primary7};
  padding: 1.25em; 
  border: 2px dashed ${styles.colors.primary9};
  margin: 0;
  border-radius: ${styles.card.borderRadius};
  `;

const SmallHeading = styled.h2`
    margin-bottom: 4px;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    font-size: 1.75rem;
    font-size: ${styles.font.size.md};
    color: ${styles.colors.font.text50};
    `

export const Task = React.memo(TaskComponent);
