import { SdiCheck, styles } from "@sdir/sds";
import { useState } from "react";
import styled from "styled-components";

export interface FilterListItemProps {
  id?: number;
  name: string;
  isChecked?: boolean;
  onCheck?: (checked: boolean) => void;
}

const FilterListItemComponent = ({
  name,
  isChecked,
  onCheck = () => {},
}: FilterListItemProps) => {
  const [checked, setChecked] = useState(isChecked);

  return (
    <ListItem>
      <Filter
        type="button"
        aria-checked={checked}
        onClick={() => {
          onCheck(!checked);
          setChecked(!checked);
        }}
      >
        {name}
        <SdiCheck height="20px" color={styles.colors.primary} />
      </Filter>
    </ListItem>
  );
};

const ListItem = styled.li``;

const Filter = styled.button`
  border: 0;
  padding: 0;
  border-radius: 4px;
  background-color: transparent;
  padding: 0.25em 0.75em;
  width: 100%;
  text-align: left;
  font: inherit;
  color: ${styles.colors.primary};
  cursor: pointer;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 4px 20px;
  svg {
    opacity: 0;
    transform: scale(0);
    transform-origin: center center;
    transition: opacity 0.1s, transform 0.15s;
  }
  &[aria-checked="true"] {
    background-color: ${styles.colors.primary10};
    svg {
      opacity: 1;
      transform: scale(1);
    }
  }
`;

export const FilterListItem = FilterListItemComponent;
